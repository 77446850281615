@import '../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-lists/styles/material.css';
@import '../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
@import '../node_modules/@syncfusion/ej2-grids/styles/material.css';
@import '../node_modules/@syncfusion/ej2-react-spreadsheet/styles/material.css';


.backg{
  background-color: #fff !important;
}

:root {
    --primary: #06A3DA;
    --secondary: #34AD54;
    --light: #EEF9FF;
    --dark: #091E3E;
    --red:#da0b0b
}



body {
  font-family: "Open Sans", sans-serif;
  background: #f6f9ff;
  color: #444444;
}

a {
 
  text-decoration: none !important;
}



button{
    border: initial;
    background-color: initial;
    color: inherit;
    font: inherit
}

.Content{
  
  position: fixed;
  top: 0;
}


.solcon{
   
    padding-bottom: 150px;
}

.textfiled{
    padding:3px !important;
   font-size: 15px !important;
   
  }


  /* Table */

  .rdt_Table .rdt_TableHeadRow {
    background:  #16488f;
    color: #E7EDEE;
   
}

.rdt_Table .rdt_TableRow {
    &:nth-of-type(even){
        background: '';
    }
}

.rdt_Table .rdt_TableBody {

    padding-top: 2px;
}

.edit-btn{
    font-size:20px ;
    color:#0d70fa ;
    transition: all 0.5s;
}

.edit-btn:hover {
    color:#0b4da8 ;
    font-size: 30px;
     
}

.btt , .btn-class {
  background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}

.del-btn{

    font-size:20px ;
    color:#da0b0b ;
    transition: all 0.5s;

}

.del-btn:hover {
    color:#a10b0b ;
    font-size: 30px;
     
}


/* Login */
.content{
margin: 8% !important;
background-color: #fff;
padding: 4rem 1rem 4rem 1rem;
box-shadow: 0 0 5px 5px rgba(0, 0, 0, .05);
}

.content-r{
    
    background-color: #fff;
  
    box-shadow: 0 0 5px 5px rgba(0, 0, 0, .05);
    }

.signin-text{
    font-style: normal !important;
    font-weight: 600 !important;

}

.btn-class{
    background-color: var(--primary);
    color: #fff;
    padding:10px 30px ;
  
    
}

.btn-class:hover{
   color: var(--dark);
   transition: all 0.5s;
   background-color: var(--primary);
}




/* SideBar */


*{
    margin: 0;
    padding: 0;
    text-decoration: none;   
}

.containere{
    display: flex;
    height: 100vh;
}

main{
    width: 100%;
    padding: 20px;
}

.sidebar{
    background: var(--dark);
    color: #fff;
    height: 100vh;
    width: 250px;
    transition: all 0.5s;
}
.top_section{
    display: flex;
    align-items: center;
    padding: 20px 15px;
}

.logo{
    font-size: 30px;
    color: #fff;
}

.bars{
    display: flex;
    font-size: 25px;
    margin-left: 50px;
}

.link{
    display: flex;
    color: #fff;
    padding: 5px 8px;
    gap: 10px;
    text-decoration: none;
    transition: all 0.5s;
}

.link:hover{
    
    color: var(--primary);
    transition: all 0.5s;
}

.active{
    background: #fff;
    color: #000;

}

.icon , .link_text{
font-size: 12px;
}


.Score_Set_up{
    display: flex;
    align-items: center;
   gap: 25px;
   

}


.delete{
    
    background: #A04000 !important;
    transition: all 0.5s;
}

.delete:hover{
    background: #c2855c !important;
     
}

.addnew{

    background:  #45668a !important ;
    transition: all 0.5s;
}
.addnew:hover{

    background:  #303841 !important ;

}

.update {
    background: #6b9ed4 !important;
    transition: all 0.5s;
}

.update:hover {
    background: rgb(209, 198, 198) !important;
}

.preview{
    background: #A04000  !important;
    color: #fff !important;
    transition: all 0.7s !important;
}

.preview:hover{
    background: #743002  !important;
   
}

.save-btn {

    background: #3163c7  !important;
    color: #fff !important;
    transition: all 0.7s !important;
    
}
.save-btn:hover{
    background: #274683  !important;
}



.preview-btn{
    font-size:20px ;
    color:#197ad4 ;
    transition: all 0.5s;
}

.preview-btn:hover {
    color:#0c345a  ;
    font-size: 30px;
     
}

.preview-btn-red{
  font-size:20px ;
  color:#d41919 ;
  transition: all 0.5s;
}

.preview-btn-red:hover {
  color:#691212  ;
  font-size: 30px;
   
}

.raw-btn{
    font-size:20px ;
    color:#cc1d2c ;
    transition: all 0.5s;
}

.raw-btn:hover {
    color:#740e18 ;
    font-size: 30px;
     
}

.excel-btn{
    font-size:20px ;
    color:#287c17 ;
    transition: all 0.5s;
}

.excel-btn:hover {
    color:#112e0b  ;
    font-size: 30px;
     
}

/*-------------------------------*/
.header {
    background-image: linear-gradient(to right ,#18A0BE ,#622DB9);
    height: 55px;
    display: flex;
    width: 100vw;
    }
    
    .logo {
    align-self: center;
    margin-left: 20px;
    margin-right: 30px;
    animation: fade-in-from-top 0.9s;
    }
    
    .dd-toggle{
    color: rgba(255 ,255,255,0.7);
    padding: 0 13px;
    cursor: pointer;
    transition: all 0.8s;
    animation: fade-in-from-top 0.9s;
    }
    
    .dd-toggle:hover {
        color: #fff;
        background-color: rgba(255 ,255,255,0.07);
    }
    
    .dd-toggle:focus{
        color: #fff;
        background-color: rgba(255 ,255,255,0.07);
        
    }
    
    .dd-toggle:nth-of-type(4){
        margin-left: auto;
    }
    
    .dd-toggle:nth-of-type(6){
        display: flex;
        align-items: center;
    }
    
   
    
    .dd-toggle__img{
        height: 30px;
        width: 30px;
        object-fit: cover;
        border-radius: 50%;
        margin-right: 7px;
    }
    

    .show_info_btn{
        border: 2px solid black;
        border-color: var(--primary);
        padding:10px 30px ;    
    }
    
    .show_info_btn:hover{
       color: var(--light);
       transition: all 0.5s;
       background: var(--primary);
    }

    .show_error_btn{
      border: 2px solid black;
      border-color: var(--red);
      padding:10px 30px ;    
  }

  .show_error_btn:hover{
    color: var(--light);
    transition: all 0.5s;
    background: var(--red);
 }

    .form-control{
        height: 35px !important;
    }

    /* Profie */

    .pagetitle {
        margin-bottom: 10px;
      }
      
      .pagetitle h1 {
        font-size: 24px;
        margin-bottom: 0;
        font-weight: 600;
        color: #012970;
      }


      /* Breadcrumbs */
.breadcrumb {
    font-size: 25px;
    color: #899bbd;
    font-weight: 600;
  }
  
  .breadcrumb a {
    color: #899bbd;
    transition: 0.3s;
  }
  
  .breadcrumb a:hover {
    color: #51678f;
  }
  
  .breadcrumb .breadcrumb-item::before {
    color: #899bbd;
  }
  
  .breadcrumb .active {
    color: #51678f;
    font-weight: 600;
  }

  .header-nav .profile {
    min-width: 240px;
    padding-bottom: 0;
    top: 8px !important;
  }
  
  .header-nav .profile .dropdown-header h6 {
    font-size: 18px;
    margin-bottom: 0;
    font-weight: 600;
    color: #444444;
  }
  
  .header-nav .profile .dropdown-header span {
    font-size: 14px;
  }
  
  .header-nav .profile .dropdown-item {
    font-size: 14px;
    padding: 10px 15px;
    transition: 0.3s;
  }
  
  .header-nav .profile .dropdown-item i {
    margin-right: 10px;
    font-size: 18px;
    line-height: 0;
  }
  
  .header-nav .profile .dropdown-item:hover {
    background-color: #f6f9ff;
  }



  .profile .profile-card img {
    max-width: 120px;
  }
  
  .profile .profile-card h2 {
    font-size: 24px;
    font-weight: 700;
    color: #2c384e;
    margin: 10px 0 0 0;
  }
  
  .profile .profile-card h3 {
    font-size: 18px;
  }
  
  .profile .profile-card .social-links a {
    font-size: 20px;
    display: inline-block;
    color: rgba(1, 41, 112, 0.5);
    line-height: 0;
    margin-right: 10px;
    transition: 0.3s;
  }
  
  .profile .profile-card .social-links a:hover {
    color: #012970;
  }
  
  .profile .profile-overview .row {
    margin-bottom: 20px;
    font-size: 15px;
  }
  
  .profile .profile-overview .card-title {
    color: #012970;
  }
  
  .profile .profile-overview .label {
    font-weight: 600;
    color: rgba(1, 41, 112, 0.6);
  }
  
  .profile .profile-edit label {
    font-weight: 600;
    color: rgba(1, 41, 112, 0.6);
  }
  
  .profile .profile-edit img {
    max-width: 120px;
  }


  /* Bordered Tabs */
.nav-tabs-bordered {
    border-bottom: 2px solid #ebeef4;
  }
  
  .nav-tabs-bordered .nav-link {
    margin-bottom: -2px;
    border: none;
    color: #2c384e;
  }
  
  .nav-tabs-bordered .nav-link:hover,
  .nav-tabs-bordered .nav-link:focus {
    color: #4154f1;
  }
  
  .nav-tabs-bordered .nav-link.active {
    background-color: #fff;
    color: #4154f1;
    border-bottom: 2px solid #4154f1;
  }


  .logo-size{
    width: 120px !important;
    height: 120px;
  }

  .bra{
    margin-bottom: 130px !important
  }
 

  .stepperSubDiv {
    margin: 7px;
    border-radius: 5px;
    padding: 0.7em;
    box-shadow: 0 0 5px 5px rgba(0, 0, 0, .05);
  }


  .btn-open{
    background: #5351be !important;
    color: #fff;
    padding:10px 30px ;
    transition: all 0.7s !important;
  }
  
  .btn-open:hover{
    background: #4a497a !important ;
  }

  .btn-closee{
    background: rgb(226, 94, 94) !important;
    color: #fff;
    padding:10px 30px ;
    transition: all 0.7s !important;
  }
  
  .btn-closee:hover{
    background: rgb(155, 71, 71) !important ;
    
  }

  .other_info{
    padding-left: 5px;
    padding-top: 5px;
    padding-right: 5px;
    padding-bottom: 5px;
  }

  .search__input {
    background-color: #E7EDEE;
    width: 400px !important ;
    height: 35px !important;
    border-radius: 500px !important ;
    outline: none !important ;
    font-size: 16px !important ;
    margin-right: 15px;
    color: #555A5B !important ;
    transition: all 0.5s;
    display: inline !important;
  }
  .search__input::placeholder {
    color: #25383d !important;
  }
  .search__input:hover {
    width: 410px;
  }

  .edit-btn{
    font-size:20px ;
    color:#0d70fa ;
    transition: all 0.5s;
}

.edit-btn:hover {
    color:#0b4da8 ;
    font-size: 30px;
     
}

.btt{
  background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}

.del-btn{

    font-size:20px ;
    color:#da0b0b ;
    transition: all 0.5s;

}

.del-btn:hover {
    color:#a10b0b ;
    font-size: 30px;
     
}

.page1{
  margin-bottom: 200px;
}


.table_buttom_margin{
  margin-bottom: 150px;
}



.lable{
  color:#7d878a;
  font-size: 14px;
}


.top-layout{
  display: flex;
  justify-content: space-between;
}

.page-Margin-bottom{
  margin-bottom: 200px;
}


.userbal{
  background-image: linear-gradient(to left ,#18A0BE ,#622DB9);
  color: #fff !important;
}


.my-modal {
  width: 90vw !important;   /* Occupy the 90% of the screen width */
  max-width: 90vw !important;
} 

.my-modal-small {
  width: 75vw !important;   /* Occupy the 90% of the screen width */
  max-width: 90vw !important;
} 

.my-modal-vsmall {
  width: 40vw !important;   /* Occupy the 90% of the screen width */
  max-width: 90vw !important;
} 



.header {
  transition: all 0.5s;
  z-index: 997;
  height: 60px;
  box-shadow: 0px 2px 20px rgba(1, 41, 112, 0.1);
  background-color: #fff;
  padding-left: 20px;
  /* Toggle Sidebar Button */
  /* Search Bar */
}

.header .toggle-sidebar-btn {
  font-size: 32px;
  padding-left: 10px;
  cursor: pointer;
  color: #012970;
}

.header .search-bar {
  min-width: 360px;
  padding: 0 20px;
}

@media (max-width: 1199px) {
  .header .search-bar {
    position: fixed;
    top: 50px;
    left: 0;
    right: 0;
    padding: 20px;
    box-shadow: 0px 0px 15px 0px rgba(1, 41, 112, 0.1);
    background: white;
    z-index: 9999;
    transition: 0.3s;
    visibility: hidden;
    opacity: 0;
  }

  .header .search-bar-show {
    top: 60px;
    visibility: visible;
    opacity: 1;
  }
}

.header .search-form {
  width: 100%;
}

.header .search-form input {
  border: 0;
  font-size: 14px;
  color: #012970;
  border: 1px solid rgba(1, 41, 112, 0.2);
  padding: 7px 38px 7px 8px;
  border-radius: 3px;
  transition: 0.3s;
  width: 100%;
}

.header .search-form input:focus,
.header .search-form input:hover {
  outline: none;
  box-shadow: 0 0 10px 0 rgba(1, 41, 112, 0.15);
  border: 1px solid rgba(1, 41, 112, 0.3);
}

.header .search-form button {
  border: 0;
  padding: 0;
  margin-left: -30px;
  background: none;
}

.header .search-form button i {
  color: #012970;
}

/*--------------------------------------------------------------
# Header Nav
--------------------------------------------------------------*/
.header-nav ul {
  list-style: none;
}

.header-nav>ul {
  margin: 0;
  padding: 0;
}

.header-nav .nav-icon {
  font-size: 22px;
  color: #012970;
  margin-right: 25px;
  position: relative;
}

.header-nav .nav-profile {
  color: #012970;
}

.header-nav .nav-profile img {
  max-height: 36px;
}

.header-nav .nav-profile span {
  font-size: 14px;
  font-weight: 600;
}

.header-nav .badge-number {
  position: absolute;
  inset: -2px -5px auto auto;
  font-weight: normal;
  font-size: 12px;
  padding: 3px 6px;
}

.header-nav .notifications {
  inset: 8px -15px auto auto !important;
}

.header-nav .notifications .notification-item {
  display: flex;
  align-items: center;
  padding: 15px 10px;
  transition: 0.3s;
}

.header-nav .notifications .notification-item i {
  margin: 0 20px 0 10px;
  font-size: 24px;
}

.header-nav .notifications .notification-item h4 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 5px;
}

.header-nav .notifications .notification-item p {
  font-size: 13px;
  margin-bottom: 3px;
  color: #919191;
}

.header-nav .notifications .notification-item:hover {
  background-color: #f6f9ff;
}

.header-nav .messages {
  inset: 8px -15px auto auto !important;
}

.header-nav .messages .message-item {
  padding: 15px 10px;
  transition: 0.3s;
}

.header-nav .messages .message-item a {
  display: flex;
}

.header-nav .messages .message-item img {
  margin: 0 20px 0 10px;
  max-height: 40px;
}

.header-nav .messages .message-item h4 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 5px;
  color: #444444;
}

.header-nav .messages .message-item p {
  font-size: 13px;
  margin-bottom: 3px;
  color: #919191;
}

.header-nav .messages .message-item:hover {
  background-color: #f6f9ff;
}

.header-nav .profile {
  min-width: 240px;
  padding-bottom: 0;
  top: 8px !important;
}

.header-nav .profile .dropdown-header h6 {
  font-size: 18px;
  margin-bottom: 0;
  font-weight: 600;
  color: #444444;
}

.header-nav .profile .dropdown-header span {
  font-size: 14px;
}

.header-nav .profile .dropdown-item {
  font-size: 14px;
  padding: 10px 15px;
  transition: 0.3s;
}

.header-nav .profile .dropdown-item i {
  margin-right: 10px;
  font-size: 18px;
  line-height: 0;
}

.header-nav .profile .dropdown-item:hover {
  background-color: #f6f9ff;
}

